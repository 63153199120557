import React from 'react'
import ProfileEdit from '../components/ProfileEdit'
import PrivateRoute from '../components/PrivateRoute'

const App = () => {
  return(
    <PrivateRoute path="/profile-edit" component={ProfileEdit} fsOnly='false' />
  )
}

export default App
