import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styles from './tabs.module.css'

class Tab extends Component {
  static propTypes = {
    activeTab: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  }

  onClick = () => {
    const { label, onClick } = this.props
    onClick(label)
  }

  render() {
    const {
      onClick,
      props: { activeTab, label },
    } = this

    let className = styles['tabListItem']

    if (activeTab === label) {
      className += ' active ' + styles['tabListActive']
    }

    return (
      <button className={className} onClick={onClick}>
        {label}
      </button>
    )
  }
}

export default Tab
