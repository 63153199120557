import React from 'react'
import styles from './form.module.css'

export default(({ handleSubmit, handleUpdate, history, username }) => (
  <form
    className={styles.form}
    method="post"
    onSubmit={event => {
      handleSubmit(event)
    }}
  >
    <p className={styles[`form__instructions`]}>
      Please enter your previous password and your updated password to change your password.
    </p>
    <label className={styles[`form__label`]}>
      Previous Password
      <input
        className={styles[`form__input`]}
        type="password"
        name="previousPassword"
        onChange={handleUpdate}
      />
    </label>
    <label className={styles[`form__label`]}>
      New Password
      <input
        className={styles[`form__input`]}
        type="password"
        name="newPassword"
        onChange={handleUpdate}
      />
    </label>
    <input className={styles[`form__button`]} type="submit" value="Update Password" />
  </form>
))
